<template>
  <page-container>
    <div class="profile-container">
      <div class="about">
        <div class="images">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/tafadzwa-mhizha.appspot.com/o/imgs%2Ftaf-lights.png?alt=media&token=24baba8d-5dd3-440f-affb-59148580adb5"
            alt="profile-image"
            class="profile-image"
            id="profile-image-3"
            loading="lazy"
          />
          <img
            src="https://firebasestorage.googleapis.com/v0/b/tafadzwa-mhizha.appspot.com/o/imgs%2Ftaf-chilling.png?alt=media&token=c1ef1313-6bca-4289-bcf9-353e38eb621a"
            alt="profile-image"
            class="profile-image"
            id="profile-image-2"
            loading="lazy"
          />
          <img
            src="https://firebasestorage.googleapis.com/v0/b/tafadzwa-mhizha.appspot.com/o/imgs%2Ftaf-biness.png?alt=media&token=0ffcb228-e8e5-4bfb-bb3b-87a4125a47ad"
            alt="profile-image"
            class="profile-image"
            id="profile-image-1"
            loading="lazy"
          />
        </div>
        <div class="profile">
          <div class="profile-header">
            <h3>Hey! I'm</h3>
            <h2 class="profile-header-descriptor"></h2>
          </div>
          <div class="profile-description">
            Born in sunny Bulawayo, Zimbabwe and cultivated in groovy Manzini,
            Eswatini - I am the love child of two distinct yet inseparable
            cultures. Equipped with a degree in Computer Science, I create
            concept-driven brand identities with a loyal following. Everything
            you need to get your business up and running; from the logo to the
            product design, web design and development. So let me help you stand
            out from the crowd and connect your services to the people who need
            them the most.
          </div>
        </div>
      </div>
      <div class="services">
        <div class="header">
          <h3 class="header-title">Services</h3>
          <div class="header-background"></div>
        </div>
        <div class="services-container">
          <div class="card">
            <div class="card-side card-front">
              <img src="@/assets/svg/globe.svg" alt="globe" class="card-icon" />
              <h2 class="card-header">Web Design & Development</h2>
            </div>
            <div class="card-side card-back">
              <img src="@/assets/svg/globe.svg" alt="globe" class="card-icon" />
              <h2 class="card-header">Web Design & Development</h2>
              <p class="card-details">
                Increase your online engagement or provide an online
                point-of-sale and boost sales with custom-made premium web
                designs tailored to your business needs.
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-side card-front">
              <img
                src="@/assets/svg/pencil.svg"
                alt="pencil"
                class="card-icon"
              />
              <h2 class="card-header">Brand Identity Design</h2>
            </div>
            <div class="card-side card-back">
              <img
                src="@/assets/svg/pencil.svg"
                alt="pencil"
                class="card-icon"
              />
              <h2 class="card-header">Brand Identity Design</h2>
              <p class="card-details">
                Everything that constitutes a brand, from the logo design to the
                copy. I create visually stunning brand identities specific to
                each business and it's needs.
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-side card-front">
              <img src="@/assets/svg/notes.svg" alt="notes" class="card-icon" />
              <h2 class="card-header">Copywriting</h2>
            </div>
            <div class="card-side card-back">
              <img src="@/assets/svg/notes.svg" alt="notes" class="card-icon" />
              <h2 class="card-header">Copywriting</h2>
              <p class="card-details">
                Every business has a story to tell, an idea to get across or a
                product to sell. I can help you accomplish these goals through
                captivating and engaging copy.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="technologies">
        <div class="header">
          <h3 class="header-title">Tech Stack</h3>
          <div class="header-background"></div>
        </div>
        <div class="icons">
          <div class="row-1 row">
            <img src="@/assets/svg/html.svg" alt="html" class="icon" />
            <img src="@/assets/svg/css.svg" alt="css" class="icon" />
            <img src="@/assets/svg/js.svg" alt="js" class="icon" />
            <img src="@/assets/svg/sass.svg" alt="sass" class="icon" />
            <img src="@/assets/svg/vuejs.svg" alt="vuejs" class="icon" />
          </div>
          <div class="row-2 row">
            <img src="@/assets/svg/node.svg" alt="nodejs" class="icon" />
            <img src="@/assets/svg/git.svg" alt="git" class="icon" />
            <img src="@/assets/svg/mongodb.svg" alt="mongodb" class="icon" />
          </div>
        </div>
      </div>
    </div>
  </page-container>
</template>


<script>
import gsap from 'gsap';
import Typed from 'typed.js';

export default {
  data() {
    return {
      period: Math.abs(2021 - new Date().getFullYear()),
      mobile: false,
      timeline: null,
    };
  },

  computed: {
    experience() {
      return this.period <= 1 ? 'year' : `${this.period} years`;
    },
  },

  methods: {
    checkScreen() {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 1066) {
        this.mobile = true;
        if (this.timeline) {
          this.timeline.revert();
        }
        return;
      }
      if (this.timeline) {
        this.timeline.restart();
      }

      this.mobile = false;
    },

    setTimeline() {
      const tl = gsap.timeline({
        repeat: -1,
        repeatDelay: 1,
        yoyo: true,
        duration: 2.2,
      });
      this.timeline = tl
        .to(
          '#profile-image-1',
          {
            autoAlpha: 0,
            x: '-18rem',
            rotate: '-10deg',
            ease: 'power2.Inout',
          },
          '+=8'
        )
        .from('#profile-image-2', { autoAlpha: 0 }, '-=.75')
        .to(
          '#profile-image-2',
          {
            autoAlpha: 0,
            x: '18rem',
            rotate: '10deg',
            ease: 'power2.Inout',
          },
          '+=8'
        )
        .from('#profile-image-3', { autoAlpha: 0 }, '-=.75')
        .to(
          '#profile-image-3',
          {
            autoAlpha: 0,
            x: '-8rem',
            rotate: '-10deg',
            ease: 'power2.Inout',
          },
          '+=8'
        );
    },
  },

  created() {
    this.checkScreen();
    window.addEventListener('resize', this.checkScreen);
  },

  mounted() {
    var options = {
      strings: [
        'Tafadzwa C. Mhizha',
        'Web Designer',
        'Web Developer',
        'Brand Strategist',
        'Graphic Designer',
        'Overall Nice Guy',
        "God's favorite :)",
      ],
      typeSpeed: 100,
      backSpeed: 75,
      backDelay: 2000,
      smartBackspace: true,
      showCursor: false,
      loop: true,
    };

    new Typed('.profile-header-descriptor', options);

    // DESKTOP ANIMATION
    if (!this.mobile) {
      setTimeout(this.setTimeline(), 1000);
    }

    // MOBILE ANIMATION
    if (this.mobile) {
      // alert('animation');
      const tl = gsap.timeline();
      setTimeout(() => {
        tl.from('#profile-image-2', {
          autoAlpha: 0,
          ease: 'power.4-out',
        }).from(
          '#profile-image-1',
          {
            autoAlpha: 0,
            ease: 'power.4-out',
          },
          '-=0.15'
        );
      }, 2400);
    }
  },
};
</script>

<style lang="scss" scoped>
@function tracking($size) {
  @return calc(1em * 0.001 * $size);
}

.profile-container {
  padding: 9.5rem 0 8rem 0;

  @media screen and (max-width: 1066px) {
    padding: 14rem 0 8rem 0;
  }

  .about {
    display: flex;
    padding-right: 11rem;
    margin-bottom: 11rem;

    @media screen and (max-width: 1066px) {
      flex-direction: column;
      padding-right: 0;
      margin-bottom: 6rem;
    }

    .profile {
      width: 56%;

      @media screen and (max-width: 1066px) {
        width: 100%;
      }

      &-header {
        text-align: center;

        h3 {
          font-size: 3.5rem;
          font-family: var(--font-header);
          letter-spacing: tracking(20);
          margin-bottom: 0.8rem;
        }

        &-descriptor {
          font-size: 7rem;
          font-family: var(--font-header);
          letter-spacing: tracking(20);
          min-height: 9rem;

          @media screen and (max-width: 420px) {
            min-height: 7rem;
            font-size: 5rem;
          }
        }
      }

      &-description {
        font-size: 2.2rem;
        text-align: center;
        margin-top: 1.8rem;
        position: relative;
        z-index: 20;

        @media screen and (max-width: 1066px) {
          width: 80%;
          margin: 1.8rem auto;
        }
      }
    }

    .images {
      width: 44%;
      position: relative;

      @media screen and (max-width: 1066px) {
        width: 100% !important;
        display: flex;
        height: 31vw;
        margin-bottom: 3rem;
      }

      .profile-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: bottom;
      }

      #profile-image-1 {
        visibility: visible;

        @media screen and (max-width: 1066px) {
          width: 34%;
          visibility: hidden;
          transform: translate(-105%, -50%) rotate(-20deg);
        }
      }

      #profile-image-2 {
        visibility: hidden;

        @media screen and (max-width: 1066px) {
          width: 32%;
          transform: translate(10%, -50%) rotate(18deg);
        }
      }

      #profile-image-3 {
        visibility: hidden;

        @media screen and (max-width: 1066px) {
          width: 32%;
          z-index: 200;
          top: 40%;
          visibility: visible !important;
        }
      }
    }
  }

  .services {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 11rem;

    @media screen and (max-width: 1066px) {
      padding: 0 2.7rem;
    }

    .header {
      margin-bottom: 5rem;
      position: relative;

      &-title {
        padding: 1.6rem 4.2rem;
        font-size: 2.4rem;
        font-family: var(--font-header);
        color: var(--color-primary);
        position: relative;
        z-index: 10;
      }

      &-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: perspective(900px) rotateY(31deg);
        background: rgba($color: #040c9b, $alpha: 0.8);
      }
    }

    &-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 7rem;
      width: 100%;
      margin-top: 5rem;

      @media screen and (max-width: 674px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 1fr);
        justify-items: center;
      }

      .card {
        perspective: 150rem;
        -moz-perspective: 150rem;
        position: relative;
        height: 37rem;
        cursor: pointer;

        @media screen and (max-width: 674px) {
          width: 50%;
        }

        @media screen and (max-width: 500px) {
          width: 70%;
        }

        &-side {
          height: 37rem;
          transition: all 0.8s ease;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          backface-visibility: hidden;
          border-radius: 4rem;
          text-overflow: ellipsis;
          overflow: hidden;
          box-shadow: 0 8px 12px rgba(black, 0.08);

          @media screen and (max-width: 1066px) {
            min-height: 37rem;
            height: auto;
          }
        }

        &-back {
          transform: rotateY(180deg);
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 2.3rem 3rem;
          background-color: white;

          @media screen and (max-width: 1066px) {
            transform: rotateY(0deg);
          }

          @media screen and (max-width: 674px) {
            padding: 5rem 3rem;
          }

          .card-icon {
            margin-bottom: 3rem;
            width: 6.3rem;

            @media screen and (max-width: 1066px) {
              width: 5.3rem;
            }
          }

          .card-details {
            text-align: center;
            margin-top: 2.4rem;
            font-size: 1.75rem;
            text-overflow: ellipsis;

            @media screen and (max-width: 500px) {
              margin-top: 4rem;
            }
            @media screen and (max-width: 400px) {
              margin-top: 3rem;
            }
          }
        }

        &-front {
          background-color: white;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 2.4rem;

          @media screen and (max-width: 1066px) {
            transform: rotateY(-180deg);
          }
        }

        &-header {
          font-size: 2.2rem;
          font-family: var(--font-header);
          letter-spacing: tracking(20);
          text-align: center;

          @media screen and (max-width: 900px) {
            font-size: 2rem;
          }
        }

        &:hover {
          .card-front {
            transform: rotateY(-180deg);
          }

          .card-back {
            transform: rotateY(0);
          }
        }
      }
    }
  }

  .technologies {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 11rem;
    margin: 13rem 0 5rem;

    @media screen and (max-width: 1066px) {
      margin: 13rem 0 2rem;
    }

    .header {
      margin-bottom: 5rem;
      position: relative;

      &-title {
        padding: 1.6rem 4.2rem;
        font-size: 2.4rem;
        font-family: var(--font-header);
        color: var(--color-primary);
        position: relative;
        z-index: 10;
      }

      &-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: perspective(900px) rotateY(31deg);
        background: rgba($color: #040c9b, $alpha: 0.8);
      }
    }

    .icons {
      width: 60%;
      text-align: center;
      height: max-content;

      .row {
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          transform: scale(0.7);
          padding: 0 6%;

          @media screen and (max-width: 800px) {
            transform: scale(0.5);
          }
          @media screen and (max-width: 500px) {
            transform: scale(0.4);
          }

          @media screen and (max-width: 358px) {
            transform: scale(0.35);
            padding: 0 4% !important;
          }

          @media screen and (max-width: 328px) {
            padding: 0% !important;
          }
        }
      }

      .row-1 {
        margin-bottom: 3rem;
      }
    }
  }
}
</style>